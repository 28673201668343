import {  useState } from "react";
import {
  CasesContainer,
  ContatoContainer,
  EmConstrucaoConatiner,
  HomeContainer,
  OqueFazemosContainer,
  QuemSomosContainer,
} from "./styles";
import VestaLogo from "../../assets/vesta-transparente-branco@2x.png";
import { ReactSVG } from "react-svg";
import seta from "../../assets/seta-baixov2.svg";
import note from "../../assets/176.png";
import setaVinho from "../../assets/setaVinho.svg";
import fundoLaranja from "../../assets/img-soluções.png";
import faixa from "../../assets/faixa.svg";
import imgManutencao from "../../assets/img-manutenção.png";
import imgContato from "../../assets/OWINK61.png";
import versaoMobile from "../../assets/183.png";
import axios from "axios";
import { toast } from "react-toastify";

const HomeV2 = () => {
  const [name, setName] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [email, setEmail] = useState("");
  const [fone, setFone] = useState("");
  const [message, setMessage] = useState("");
  const [cargo, setCargo] = useState("");
  const [enviando, setLoading] = useState(false); 


  async function sendEmail(e) {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await axios.post("https://apioutsourcing.vesta.com.br/email", {
        name,
        empresa,
        email,
        fone,
        cargo,
        message,
      });
      
      if (response.status === 200) {
        toast.success("E-mail enviado com sucesso");
        setName("");
        setEmpresa("");
        setEmail("");
        setFone("");
        setCargo("");
        setMessage("");
      } else {
        toast.error("Erro ao enviar e-mail");
        console.error("Erro ao enviar o e-mail:", response);
      }
    } catch (error) {
      console.error("Erro ao enviar o e-mail:", error);
  } finally {
    setLoading(false); 
  }
  }

  const scrollToContato = () => {
    const contatoElement = document.getElementById("contato");
    if (contatoElement) {
      contatoElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <HomeContainer>
      <QuemSomosContainer id="quem-somos">
        <div className="description-container">
          <div className="logo">
            <img src={VestaLogo} alt='logo' />
            <h1>GESTÃO EM COMPRAS E OUTSOURCING 4.0</h1>
          </div>
          <div onClick={scrollToContato} className="arrow-container">
            <ReactSVG src={seta} />
          </div>
        </div>
      </QuemSomosContainer>
      <OqueFazemosContainer id="oque-fazemos">
        <div className="divLeft">
          <img src={note} alt="notebook"/>
        </div>
        <div className="divRight">
          <div className="box">
            <div className="tech">
              <h2>PROCUREMENT</h2>
              <div>
                <h3>TECH</h3>
              </div>
            </div>
            <p>
              A Vesta está comprometida em revolucionar a forma com que as
              empresas lidam com seus processos de compras, unindo tecnologia de
              ponta, expertise em gestão e responsabilidade ambiental.
            </p>
            .
            <p>
              Descubra como nossas soluções integradas podem transformar sua
              área de procurement e impulsionar o seu negócio.
            </p>
            <div className="conheca-empresa">
              <button onClick={scrollToContato}>
                CONHEÇA NOSSA EMPRESA
                <p>
                  <ReactSVG src={setaVinho} />
                </p>
              </button>
            </div>
          </div>
        </div>
      </OqueFazemosContainer>
      <CasesContainer>
        <div className="divLeft">
          <div className="tech">
            <h2>NOSSAS</h2>
            <div>
              <h3>SOLUÇÕES</h3>
            </div>
          </div>
          <ul>
            <li>OUTSOURCING</li>
            <li>ECO INTELIGÊNCIA</li>
            <li>ANÁLISE DE DADOS</li>
            <li>MÓDULO DE VITRINE</li>
          </ul>
          <div className="boxSaiba-mais">
            <button onClick={scrollToContato}className="saiba-mais">
              SAIBA MAIS
              <p>
                <ReactSVG src={setaVinho} />
              </p>
            </button>
          </div>
        </div>

        <div className="divRight">
          <img className="fundoLaranja" src={fundoLaranja} alt="fundo" />
          <img className="semFundoMobile" src={versaoMobile} alt="imgem-computador" />
        </div>
      </CasesContainer>

      <EmConstrucaoConatiner>
        <img className="faixa" src={faixa} alt="site em manutenção" />
        <div className="manutencao">
          <div className="left">
            <img src={imgManutencao} alt="em-manutenção" />
          </div>
          <div className="right">
            <h2>SITE EM CONSTRUÇÃO</h2>
            <p>
              Estamos otimizando nosso site para oferecer a você uma melhor
              experiência.
            </p>
          </div>
        </div>
        <img className="faixa" src={faixa} alt="manutenção" />
      </EmConstrucaoConatiner>

      <ContatoContainer id="contato">
        <div className="boxContato">
          <form className="left" onSubmit={sendEmail}>
            <div>
              <h2>Fale conosco</h2>
              <input
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                placeholder="Nome*"
              />
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                placeholder="E-mail*"
              />
              <input
                type="text"
                onChange={(e) => setFone(e.target.value)}
                value={fone}
                required
                placeholder="Telefone*"
              />
              <input
                type="text"
                onChange={(e) => setEmpresa(e.target.value)}
                value={empresa}
                required
                placeholder="Empresa*"
              />
              <input
                type="text"
                onChange={(e) => setCargo(e.target.value)}
                value={cargo}
                required
                placeholder="Cargo"
              />
              <textarea
                type="text"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
                placeholder="Mensagem*"
              />
              <div className="button">
                
                <button type="submit">
                {enviando ? "Enviando..." : "Enviar"}

                  <p>
                    <ReactSVG src={setaVinho} />
                  </p>
                </button>
              </div>
            </div>
          </form>
          <div className="right">
            <img src={imgContato} alt="img-notebook" />
          </div>
        </div>
      </ContatoContainer>
    </HomeContainer>
  );
};

export default HomeV2;
